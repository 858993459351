import {useCoordinationRole, useServerTime} from '@glark-newco/game-library';
import {useEffect} from 'react';
import {useCrunchTime} from '../hooks/useCrunchTime';
import {useIsGameStarted, usePlanningEnd} from '../state/signlinesGameState';

let initialCrunchTimeEventBeenPublished: boolean = false;

export function CrunchTimeListener() {
    const {publishCrunchTimeEvent, isCrunchTime} = useCrunchTime();
    const {isCoordinator} = useCoordinationRole();
    const isGameStarted  = useIsGameStarted();
    const serverTime = useServerTime();
    const {planningEndTime} = usePlanningEnd();

    useEffect(() => {
        if (isCoordinator && !initialCrunchTimeEventBeenPublished) {
            if (isGameStarted && !isCrunchTime && serverTime.currentTime.diff(planningEndTime) > 0) {
                publishCrunchTimeEvent();
                initialCrunchTimeEventBeenPublished = true;
            }
        }
    }, [isCoordinator, isGameStarted, serverTime, planningEndTime, isCrunchTime]);

    return null;
}
