import {GameChatPanel, PlayersPanel, usePlayers} from '@glark-newco/game-library';
import {Stack} from '@mantine/core';
import {FacilitatorActions} from './FacilitatorActions';
import {RulesButton} from './RulesButton';
import {SignlinesAvatarProvider} from './SignlinesAvatar';

export function GamePlayOverlay() {
    const {localPlayer} = usePlayers();

    return (
        <>
            <PlayersPanel
                avatar={SignlinesAvatarProvider}
                style={{
                    width: '18%',
                    height: '65%',
                    backgroundColor: 'rgba(0,0,0,0.0)',
                    left: '4%',
                    top: '24%',
                }}/>
            <Stack gap={0}>
                {localPlayer.isFacilitator &&
                    <FacilitatorActions/>
                }
                <RulesButton startOpen={true}/>
                <GameChatPanel avatar={SignlinesAvatarProvider} style={{
                    right: '4%',
                    top: '10%',
                    width: '18%',
                    height: '75%',
                    backgroundColor: 'rgba(0,0,0,0)'}}/>
            </Stack>
        </>
    );
}
