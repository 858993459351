import {Center, CloseButton, Image, List, Paper, UnstyledButton} from '@mantine/core';
import {useDisclosure, useHover} from '@mantine/hooks';

interface RulesButtonProps {
    startOpen:boolean;
}

export function RulesButton({startOpen}: RulesButtonProps) {
    const {hovered, ref} = useHover();
    const [opened, {open, close}] = useDisclosure(startOpen);
    return <>
        <Paper bg={'url(/images/rules_background_parchment.svg) 0 0/100% 100%'} pos={'relative'}
            left={'15%'} top={'10%'} w={'70%'} h={'80%'} style={{pointerEvents: 'initial'}}
            p={'7% 10%'} display={opened ? 'initial' : 'none'}>
            <CloseButton onClick={close} data-testid={'btn_close_rules'} pos={'absolute'} top={'10%'} right={'20%'}
                size={'xl'}/>
            <Center>
                <h1>Signlines Rules</h1>
            </Center>
            <List spacing="lg" p={'0% 5%'} icon={<Image src={'images/rules-icon.svg'} w={'2em'} h={'2em'}/>}
                styles={{item: {fontSize: '1.4rem', fontWeight: 'bold'}}}
            >
                <List.Item>
                    There are 30 tiles distributed among the group, which together form a complete grid.
                    Each tile only has one correct position within the grid.
                </List.Item>
                <List.Item>Your task is to complete the grid with each tile in it`&apos;s correct location.</List.Item>
                <List.Item>
                    You have 30 minutes to plan without seeing each others tiles. Once the planning time has
                    elapsed, you will have 20 seconds to correctly assemble the grid.
                </List.Item>
                <List.Item>
                    No tile may be shown to any other participant. No screen-share or recording of symbols is
                    permitted during the exercise. Information exchange is through verbal communication or the
                    in-game chat only.
                </List.Item>
                <List.Item>
                    Tiles can be dragged into the grid, and may be double-clicked to view larger. If you have
                    more than 4 tiles you can scroll your personal tiles with the left and right chevrons.
                </List.Item>
            </List>
        </Paper>
        <UnstyledButton onClick={open} pos={'absolute'} top={'0.2%'} right={'4%'} size={'md'}
            bg={`url(/images/rules_button${hovered ? '_hover' : ''}.svg) 0 0/100% 100%`} w={'7%'} h={'5%'}
            style={{pointerEvents: 'initial'}} ref={ref} aria-label={'Rules'}
        />
    </>;
}