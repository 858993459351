import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';

import {GameCanvasWrapper, TeamvineProvider} from '@glark-newco/game-library';
import {createTheme, defaultVariantColorsResolver, VariantColorsResolver} from '@mantine/core';
import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
import {signlinesOverlaySupplier} from './game/components/SignlinesOverlaySupplier';
import {SignLinesSceneMutator} from './game/components/SignLinesSceneMutator';
import {SignlinesCanvas} from './game/SignlinesCanvas';
import {PixiAppProvider} from './PixiAppProvider';


const variantColorResolver: VariantColorsResolver = (input) => {
    // See https://mantine.dev/styles/variants-sizes/#variantcolorresolver.
    // More flexible: https://mantine.dev/styles/variants-sizes/#custom-variants-types

    // Custom variant for "global" controls - e.g. VolumeButton and FullScreenButton
    if (input.variant === 'global') {
        return {
            background: '#252217',
            hover: '#353024',
            color: '#e6b54c',
            border: '0.1em solid #886b28',
        };
    }
    return defaultVariantColorsResolver(input);
};

createRoot(document.getElementById('root') as HTMLElement).render(
    <StrictMode>
        <TeamvineProvider sceneMutator={SignLinesSceneMutator} gameType={'SignLines'} theme={createTheme({
            variantColorResolver,
        })}>
            <GameCanvasWrapper gameName={'Signlines'} gameType={'SignLines'} overlaySupplier={signlinesOverlaySupplier}>
                <PixiAppProvider/>
                <SignlinesCanvas/>
            </GameCanvasWrapper>
        </TeamvineProvider>
    </StrictMode>,
);
