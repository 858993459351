import {Button} from '@mantine/core';
import {IconPlayerPlay} from '@tabler/icons-react';
import {useCrunchTime} from '../hooks/useCrunchTime';

export function CrunchTimeButton() {
    const {publishCrunchTimeEvent, crunchTimeStartTime} = useCrunchTime();

    if (crunchTimeStartTime)
        return <></>;

    return (
        <Button
            data-testid="crunchtime-override"
            role="button"
            name="start game"
            rightSection={<IconPlayerPlay size={14}/>}
            radius={'xl'}
            variant="gradient"
            onClick={() => publishCrunchTimeEvent()}
            gradient={{from: 'blue', to: 'teal', deg: 90}}>
            Skip to Crunch-Time
        </Button>
    );
}
