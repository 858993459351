import {gameStateAtom, isPlayerAction, PlayerAction, PlayerId, ReplacedPlayerMap, replacedPlayerMapSelector} from '@glark-newco/game-library';
import dayjs, {Dayjs} from 'dayjs';
import {selector, selectorFamily, useRecoilValue} from 'recoil';
import {AvatarInterface, defaultAvatar} from '../hooks/usePlayerAvatars';
import {
    isCrunchTimePayload,
    isPlayerAvatarPayload,
    isStartTimerPayload,
    isTilePayload,
    StartTimerEvent,
    TilePayload,
} from '../SignlinesPayloadTypes';


interface AvatarMap {
    [key: PlayerId]: AvatarInterface
}


///////////////////////   MUTATORS   ///////////////////////

function updatePlayerId(payload:  TilePayload, replacedPlayers: ReplacedPlayerMap): TilePayload {
    if ('playerId' in payload && payload.playerId in replacedPlayers) {
        return {
            ...payload,
            'playerId': replacedPlayers[payload.playerId],
        };
    }
    return payload;
}


/////////////////////////   ATOMS   ////////////////////////

interface PlanningEndType {
    planningDurationMS?: number;
    planningEndTime?:  Dayjs;
}

const planningEndSelector = selector<PlanningEndType>({
    key: 'planningEnd',
    get: ({get}) => {
        const gameState = get(gameStateAtom);
        return gameState.gameActions
            .filter((e) => isPlayerAction(e))
            .filter((e) => isStartTimerPayload(e.payload))
            .reduce((_initial: {}, event: PlayerAction) => {
                const payload = event.payload as StartTimerEvent;
                return {
                    planningDurationMS: payload.duration * 60 * 1000,
                    planningEndTime: dayjs(event.timestamp).add(payload.duration, 'minutes'),
                } as PlanningEndType;
            }, {} as PlanningEndType);
    },
});

const gameStartedSelector = selector<boolean>({
    key: 'gameStarted',
    get: ({get}) => {
        const {planningEndTime} = get(planningEndSelector);
        return !!planningEndTime;
    },
});

const tileActionsSelector = selector<TilePayload[]>({
    key: 'tileActions',
    get: ({get}) => {
        const gameState = get(gameStateAtom);
        const replacedPlayers = get(replacedPlayerMapSelector);
        return gameState.gameActions
            .filter((e) => isPlayerAction(e))
            .map((e) => (e).payload)
            .filter((p) => isTilePayload(p))
            .map((p) => updatePlayerId(p, replacedPlayers));
    },
});

export const playerAvatarStateSelector = selector<AvatarMap>({
    key: 'playerAvatars',
    get: ({get}) => {
        const gameState = get(gameStateAtom);
        return gameState.gameActions
            .filter((e) => isPlayerAction(e))
            .filter((e) => isPlayerAvatarPayload(e.payload))
            .reduce((partialMap: AvatarMap, event: PlayerAction) => {
                if (!event.playerId || !isPlayerAvatarPayload(event.payload))
                    throw new Error;
                partialMap[event.playerId] = event.payload.avatar;
                return partialMap;
            }, {} as AvatarMap);
    },
});

export const playerAvatarSelector = selectorFamily<AvatarInterface, PlayerId>({
    key: 'playerAvatar',
    get: (playerId: PlayerId) => ({get}) => {
        const avatars = get(playerAvatarStateSelector);
        if (playerId in avatars)
            return avatars[playerId];
        return defaultAvatar;
    },
});

export const usePlayerAvatar = (playerId: PlayerId) => {
    return useRecoilValue(playerAvatarSelector(playerId));
};

const crunchTimeStartTimeSelector = selector<Dayjs | undefined>({
    key: 'crunchTimeStartTime',
    get: ({get}) => {
        const gameState = get(gameStateAtom);
        const crunchTimeEvents = gameState.gameActions
            .filter((e) => isPlayerAction(e))
            .filter((e) => isCrunchTimePayload(e.payload));
        if (crunchTimeEvents.length > 0) {
            // For testing mainly, incase you want to trigger crunchTime multiple times
            // return dayjs(crunchTimeEvents[crunchTimeEvents.length - 1].timestamp);
            return dayjs(crunchTimeEvents[0].timestamp);
        }
        return;
    },
});

export const useCrunchTimeStartTime = () => useRecoilValue(crunchTimeStartTimeSelector);
export const useIsGameStarted = () => useRecoilValue(gameStartedSelector);
export const usePlanningEnd = () => useRecoilValue(planningEndSelector);
export const useTileEvents = () => useRecoilValue(tileActionsSelector);
