import {Container, Sprite} from '@pixi/react';
import {Assets} from 'pixi.js';
import {useCallback, useMemo} from 'react';

import {usePlayerAvatars} from '../hooks/usePlayerAvatars';
import {usePlayerAvatar} from '../state/signlinesGameState';

interface AvatarProps {
    playerId: string,
    x: number,
    y: number,
}

export function Avatar(props: AvatarProps) {
    const {build} = usePlayerAvatars();
    const avatar = usePlayerAvatar(props.playerId);
    
    const layers = useMemo(() => build(avatar), [build, avatar]);

    const AvatarOption = useCallback(({sprite}: {sprite: string | undefined}) => {
        if (!sprite) { return <></>; }
        return <Sprite
            anchor={.5}
            key={'pixi-avatar-' + sprite}
            texture={Assets.get(sprite)}
            scale={.1}
            x={props.x}
            y={props.y}
        />;
    }, [props]);

    return (
        <Container>
            <AvatarOption sprite={layers.Body}/>
            <AvatarOption sprite={layers.Hair}/>
            <AvatarOption sprite={layers.Clothing}/>
            <AvatarOption sprite={layers.Accessory}/>
            <AvatarOption sprite={layers.Beard}/>
            <AvatarOption sprite={layers.Glasses}/>
            <AvatarOption sprite={layers.Hat}/>
        </Container>
    );
}