import {DndContext, DragEndEvent, useDraggable, useDroppable} from '@dnd-kit/core';
import {PlayerBadge, Sprite, usePlayers} from '@glark-newco/game-library';
import {Box, Grid, ScrollArea, Stack, Text} from '@mantine/core';
import {ReactNode} from 'react';
import {SignlinesAvatarProvider} from './SignlinesAvatar';
import {TILE_BACKGROUND} from '../assetManifest';
import {useFacilitatorOptions} from '../state/FacilitatorOptionsAtom';

const fakeTiles: boolean = import.meta.env.VITE_APP__FAKE_TILES === 'true';

export function Droppable(props: {id: string, children: ReactNode}) {
    const {isOver, setNodeRef} = useDroppable({id: props.id});
    return (
        <div style={{minHeight: '50px', minWidth: '100%', backgroundColor: isOver ? 'rgba(0,0,0,0.25)' : undefined}} ref={setNodeRef}>
            {props.children}
        </div>
    );
}

export function Draggable(props: {id: string, children: ReactNode}) {
    const tileSpriteName: string = fakeTiles ? TILE_BACKGROUND : `tile_${props.id}`;
    const {attributes, listeners, setNodeRef, transform} = useDraggable({id: props.id});

    const style = transform ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    } : undefined;

    return (
        <Box ref={setNodeRef} style={{display: 'inline-block', position: 'relative', maxWidth: '15%', margin: '.8%', ...style}} {...listeners} {...attributes}>
            {fakeTiles && <Text style={{
                position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', 
                WebkitTextFillColor: '#ee4333 #66527e', WebkitTextStroke: '#ee0000', 
                letterSpacing: 6}}>{props.id}</Text>}
            <Sprite sprite={tileSpriteName}/>
        </Box>
    );
}

export function FacilitatorTileMoving() {
    const {getTilesAssignedToPlayer, facilitatorOptions, isPlayerSelected, giveSelectedPlayersTiles} = useFacilitatorOptions();
    const {players} = usePlayers();

    function handleDragEnd(event: DragEndEvent) {
        const {active, over} = event;
        if (!over) { return; }
    
        const newTileDeal = {...facilitatorOptions.selectedPlayersTiles};
        for (const [key] of Object.entries(newTileDeal)) {
            newTileDeal[key] = newTileDeal[key].filter((tile) => tile !== active.id);
        }
        (newTileDeal[over.id] as string[]).push(active.id as string);

        giveSelectedPlayersTiles(newTileDeal);
    }
  
    return (
        <DndContext onDragEnd={handleDragEnd}>        
            <Box p={'sm'} data-testid='modal-confirm-tiles-page'>
                <Box h={'10%'}><Text style={{textTransform: 'uppercase', fontWeight: 'bold', marginBottom: '1%'}}>Confirm Tiles</Text></Box>
                <ScrollArea data-testid='distribute-cards-scroll-area' scrollbars="y" h={'60vh'} type={'auto'}>
                    <Stack gap={'xl'} style={{overflow: 'hidden'}}>
                        {players.filter(player => isPlayerSelected(player.playerId)).map((player) => (
                            <Grid key={`confirm_tiles_${player.playerId}`}>
                                <Grid.Col span={2}>
                                    <PlayerBadge player={player} avatar={SignlinesAvatarProvider}/>
                                </Grid.Col>
                                <Grid.Col span={10}>
                                    <Droppable key={player.playerId} id={player.playerId}>
                                        <Box style={{backgroundColor: 'rgba(0,0,0,.2)', borderRadius: '12px', border: '1px solid #666'}}>
                                            {getTilesAssignedToPlayer(player.playerId).map(tile => (
                                                <Draggable key={`tile_${tile}`} id={tile}>{tile}</Draggable>
                                            ))}
                                        </Box>
                                    </Droppable>
                                </Grid.Col>
                            </Grid>
                        ))}
                    </Stack>
                </ScrollArea>
            </Box>
        </DndContext>
    );
}