import {useApp} from '@pixi/react';
import {Application} from 'pixi.js';
import {useEffect} from 'react';

declare global {
    interface Window {
        __pixiApp?: Application;
    }
}

export const PixiAppProvider = () => {
    const app = useApp(); // Get PixiJS app instance

    useEffect(() => {
        window.__pixiApp = app; // Expose PixiJS app globally
        return () => {
            delete window.__pixiApp; // Clean up on unmount
        };
    }, [app]);

    return null; // This component does not render anything
};
