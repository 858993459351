import {PlayerBadge, usePlayers} from '@glark-newco/game-library';
import {Alert, Box, Button, Group, Paper, SimpleGrid, Stack, Text, Title} from '@mantine/core';
import {IconInfoCircle} from '@tabler/icons-react';
import {SignlinesAvatarProvider} from './SignlinesAvatar';
import {useFacilitatorOptions} from '../state/FacilitatorOptionsAtom';

interface FacilitatorDealCardsConsentProps {
    onConfirm: () => void, 
    onDecline: () => void
}

export const FacilitatorDealCardsConsent = ({onConfirm, onDecline}: FacilitatorDealCardsConsentProps) => {
    const {selectedPlayersLength, isPlayerSelected} = useFacilitatorOptions();
    const {players} = usePlayers();

    return <>
        <Box p={'lg'}>
            <Title order={3} mb={'xl'}>Deal cards to all players</Title>
            <Stack gap={'lg'}>
                {selectedPlayersLength() < 2 &&
                    <Alert variant="light" color="red" title="Please assign players" icon={<IconInfoCircle/>}>
                        Insufficient players have been allocated. Please allocate more players. Signlines needs
                        at least 2 players to begin play.
                    </Alert>
                }

                <Paper p="md" shadow="xl" style={{backgroundColor: 'rgba(255,255,255,.5)'}} withBorder>
                    <Text mb={'md'}>{selectedPlayersLength()} player(s) will participate:</Text>
                    <SimpleGrid cols={4} spacing={'xs'}>
                        {players.filter(p => isPlayerSelected(p.playerId)).map(p =>
                            <PlayerBadge player={p} avatar={SignlinesAvatarProvider} key={p.playerId}/>)}
                    </SimpleGrid>
                </Paper>

                {selectedPlayersLength() < players.length &&
                    <Paper p="md" shadow="xl" style={{backgroundColor: 'rgba(255,255,255,.5)'}} withBorder>
                        <Text mb={'md'}>{players.length - selectedPlayersLength()} player(s) will observe:</Text>
                        <SimpleGrid cols={4} spacing={'xs'}>
                            {players.filter(p => !isPlayerSelected(p.playerId)).map(p =>
                                <PlayerBadge player={p} avatar={SignlinesAvatarProvider} key={p.playerId}/>)}
                        </SimpleGrid>
                    </Paper>
                }

                <Alert variant="light" color="blue" icon={<IconInfoCircle/>}
                    title="Are all players logged in and assigned the correct tiles?">
                        Dealing tiles is final, you will not be able to make changes when the game is in play.
                </Alert>

                <Group justify={'right'} mt={'lg'}>
                    <Button data-testid={'modal-deal-btn'} onClick={onConfirm}>Deal tiles</Button>
                    <Button onClick={onDecline} variant={'outline'}>Cancel</Button>
                </Group>
            </Stack>
        </Box>
    </>;
};