import {InteractionHandler, PointerHandler} from '@glark-newco/game-library';
import {FederatedPointerEvent} from '@pixi/events';
import {Graphics as PixiGraphics} from '@pixi/graphics';
import {Graphics, Sprite, Text} from '@pixi/react';
import * as PixiAnimated from '@pixi/react-animated';
import {Sound} from '@pixi/sound';
import {Assets} from 'pixi.js';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Spring, SpringValues} from 'react-spring';
import {TileSprite} from './TileSprite';
import {DRAG, TILE_BACKGROUND} from '../assetManifest';
import {textStyle} from '../constants';
import {useGameOver} from '../hooks/useGameOver';
import {Tile, useLocalPlayerTiles} from '../hooks/useSignlinesBoard';
import {useIsGameStarted} from '../state/signlinesGameState';
import {useTileInteraction} from '../state/TileDraggingState';
import {TILE_WIDTH} from '../utils/GameBoardUtils';


const TILE_PADDING = 5;
const TILE_SPACING = TILE_WIDTH + TILE_PADDING;
const CAROUSEL_WIDTH = (TILE_SPACING * 4);


interface PlayerTileProps {
    tile: Tile;
    index: number;
}

export function PlayerTile({tile, index}: PlayerTileProps) {
    const {startDragging, isDragging, handleDragEnd, setZoom, cancelDrag} = useTileInteraction();
    const {isGameOver} = useGameOver();

    const handleDragStart: PointerHandler = useCallback(() => {
        if (isGameOver) return;
        startDragging(tile, {x: 75, y: 50});
    }, [startDragging, tile, isGameOver]);

    const handleDoubleClick: PointerHandler = useCallback(() => {
        void setZoom(tile);
    }, [tile]);

    const interactionHandler = useMemo(() =>
        new InteractionHandler({handleDragStart, handleDragEnd: (event) => void handleDragEnd(event), handleDoubleClick}), []);

    useEffect(() => {
        interactionHandler.reInitialise({handleDragStart, handleDragEnd: (event) => void handleDragEnd(event), handleDoubleClick}, isDragging);
    }, [handleDragStart, isDragging, handleDragEnd, handleDoubleClick]);

    useEffect(() => {
        if (isGameOver) {
            cancelDrag();
        }
    }, [isGameOver, cancelDrag]);

    return (
        <TileSprite
            tileId={tile.tileId}
            x={index * (TILE_SPACING)} y={5}
            data-testid={`PlayerTile_${tile.tileId}`}
            anchor={0}
            eventMode={'static'}
            pointerdown={(event: FederatedPointerEvent) => !isGameOver ? interactionHandler.handleMouseDown(event) : null}
            pointerup={(event: FederatedPointerEvent)  => interactionHandler.handleMouseUp(event)}
            pointerupoutside={(event: FederatedPointerEvent) => interactionHandler.handleMouseUp(event)}
        />
    );
}


function Carousel(props: SpringValues<{x: number}>) {
    const mask = useRef<PixiGraphics | null>(null);
    const [renderComplete, setRenderComplete] = useState<boolean>(false);
    const localTiles = useLocalPlayerTiles();

    const playerTiles = useMemo(() => {
        if (!renderComplete)
            // Allow the MASK to be applied to the container before we fill the container
            return <></>;
        return localTiles.map((tile, idx) =>
            <PlayerTile key={idx} tile={tile} index={idx}/>,
        );
    }, [localTiles, mask.current]);

    useEffect(() => {
        // Ensure this panel is rendered twice when first displayed, so that the Mask gets applied correctly
        if (!renderComplete)
            setRenderComplete(true);
    }, []);

    return (
        <>
            {/*
            // @ts-expect-error Type hierarch to deep and possibly infinite */}
            <PixiAnimated.Container
                y={845}
                mask={mask.current}
                height={95} width={TILE_SPACING * localTiles.length}
                {...props}
            >
                {playerTiles}
            </PixiAnimated.Container>
            <Graphics
                draw={g => {
                    g.beginFill(0x000000, 0.0001);
                    g.drawRect(585, 845, CAROUSEL_WIDTH, 100);
                    g.endFill();
                }}
                ref={mask}
            />
        </>
    );
}

export function PlayersTiles() {
    const localTiles = useLocalPlayerTiles();
    const isGameStarted = useIsGameStarted();
    const [x, setX] = useState(0);

    const maxX = useMemo(() => {
        return (TILE_SPACING * localTiles.length) - CAROUSEL_WIDTH;
    }, [localTiles]);

    const handleMoveCarousel = (nx: number) => {
        setX(nx);
        const dragSound = Assets.get<Sound>(DRAG);
        dragSound.volume = 0.2;
        void dragSound.play();
    };

    if (!isGameStarted || localTiles.length === 0)
        return <></>;
    return (
        <>
            {x !== 0 &&
                <Sprite
                    image={TILE_BACKGROUND}
                    x={525} y={875}
                    width={50} height={50}
                    click={() => handleMoveCarousel((x - TILE_SPACING) < 0 ? 0 : x - TILE_SPACING)}
                    eventMode={'static'}
                >
                    <Text text={'<'} style={textStyle({fill: ['#ee4333', '#66527e'], stroke: '#ee0000'})} x={50} y={-10} scale={4}/>
                </Sprite>
            }
            <Spring to={{x: 585 - x}}>
                {(props) => <Carousel {...props}/>}
            </Spring>
            {x !== maxX &&
                <Sprite
                    image={TILE_BACKGROUND}
                    x={1335} y={875}
                    width={50} height={50}
                    click={() => handleMoveCarousel((x + TILE_SPACING) > (maxX) ? maxX : x + TILE_SPACING)}
                    eventMode={'static'}
                >
                    <Text text={'>'} style={textStyle({fill: ['#ee4333', '#66527e'], stroke: '#ee0000'})}  x={50} y={-10} scale={4}/>
                </Sprite>
            }
        </>
    );
}
