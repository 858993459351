import {useGameState} from '@glark-newco/game-library';
import {Group} from '@mantine/core';
import dayjs from 'dayjs';
import {useEffect, useState} from 'react';
import {CrunchTimeButton} from './CrunchTimeButton';
import {DealTilesButton} from './DealTilesButton';
import {EndGameButton} from './EndGameButton';
import {StartTimerButton} from './StartTimerButton';
import {useGameCompletion} from '../hooks/useSignlinesBoard';
import {useCrunchTimeStartTime, useIsGameStarted} from '../state/signlinesGameState';

export function FacilitatorActions() {
    const {gameState} = useGameState();
    const crunchTimeStartTime = useCrunchTimeStartTime();
    const isGameStarted  = useIsGameStarted();
    const {isComplete} = useGameCompletion();
    const [isGameTimeExpired, setGameTimeExpired] = useState(false);

    useEffect(() => {
        if (!crunchTimeStartTime) return;
        const delaySeconds = crunchTimeStartTime.add(20, 'seconds').unix() - dayjs().unix();
        const timeoutId = setTimeout(() => {
            // Ensure this component gets re-rendered at the relevant point in time.
            setGameTimeExpired(true);
        }, delaySeconds > 0 ? delaySeconds * 1000 : 0);
        return () => clearTimeout(timeoutId);
    }, [crunchTimeStartTime]);

    // Not using useGameOver here as that depends on ServerTime and our overlay isn't inside a <ServerTimeProvider/>
    const gameOver = !!crunchTimeStartTime && (isComplete || isGameTimeExpired);

    return (<>
        <Group
            data-testid="facilitator-actions"
            style={{
                width: '20%',
                position: 'absolute',
                pointerEvents: 'initial',
                right: '1rem',
                top: '2.5rem',
            }}>
            { gameState.scene === 'LOBBY' &&
                <DealTilesButton/>
            }
            { gameState.scene === 'PLAY' && !isGameStarted &&
                <StartTimerButton/>
            }
            { gameState.scene === 'PLAY' && isGameStarted &&
                <CrunchTimeButton/>
            }
            { gameState.scene === 'PLAY' && gameOver &&
                <EndGameButton/>
            }
        </Group>
    </>);
}
