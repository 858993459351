
import {PlayerId, useGameServerConnection, usePlayers} from '@glark-newco/game-library';
import {Box, Button, Flex, Modal} from '@mantine/core';
import {useDisclosure} from '@mantine/hooks';
import {Notifications} from '@mantine/notifications';
import {IconPlayerPlay} from '@tabler/icons-react';
import {useCallback, useEffect, useState} from 'react';
import styles from './DealTilesButton.module.css';
import {FacilitatorDealCardsConsent} from './FacilitatorDealTilesConsent';
import {FacilitatorOptionsPanel} from './FacilitatorOptionsPanel';
import {FacilitatorTileMoving} from './FacilitatorTileMoving';
import {DealTilesEvent, DisplayPlaySceneEvent, SignlinesPayload} from '../SignlinesPayloadTypes';
import {dealOptions, useFacilitatorOptions} from '../state/FacilitatorOptionsAtom';
import {PlayerHand} from '../utils/SignlinesDealUtils';

export function DealTilesButton() {
    const {
        facilitatorOptions,
        addSelectedPlayer,
        selectedPlayersLength,
        getTilesAssignedToPlayer,
        giveSelectedPlayersTiles} = useFacilitatorOptions();
    const [opened, {open, close}] = useDisclosure(false);
    const {publishPlayerAction} = useGameServerConnection<SignlinesPayload>();
    const {players} = usePlayers();
    const [step, setStep] = useState(0);

    // Only when modal is opened active users selected by default
    useEffect(() => {
        const addedPlayers: PlayerId[] = [];
        players.filter(player => player.isActive && !player.isFacilitator).map(player => {
            addedPlayers.push(player.playerId);
        });
        addSelectedPlayer(addedPlayers);
    }, [opened]);

    const openStartModal = useCallback(() => {
        const nonFacilitatorPlayers = players.filter(p => !p.isFacilitator);
        if (nonFacilitatorPlayers.length < 2) {
            Notifications.show({
                color: 'red',
                title: 'Error - cannot deal tiles',
                message: 'At least 2 players are required (excluding facilitators)',
                autoClose: 5000,
            });
            return;
        }
        open();
    }, [players]);

    const dealTiles =  useCallback(() => {
        if (selectedPlayersLength() <= 1) { return; }

        const dealOption = dealOptions.findLast(o => o.name === facilitatorOptions.dealStrategy);
        if (!dealOption)
            throw new Error('Cannot deal without a selected DealOption');

        const dealtTiles: PlayerHand[] = dealOption.dealer(selectedPlayersLength());
        if (dealtTiles?.length !== selectedPlayersLength())
            throw new Error(`Dealer produced an incorrect number of hands.  Expected ${selectedPlayersLength()} hands but ${dealtTiles?.length} were dealt.`);

        const newPlayerTiles = {...facilitatorOptions.selectedPlayersTiles};
        Object.keys(facilitatorOptions.selectedPlayersTiles).forEach((playerId, i) => {
            newPlayerTiles[playerId] = dealtTiles[i];
        });

        giveSelectedPlayersTiles(newPlayerTiles);
        setStep(1);
    }, [facilitatorOptions, players]);


    const startGame =  useCallback(() => {
        if (selectedPlayersLength() <= 1) { return; }
        Object.keys(facilitatorOptions.selectedPlayersTiles).forEach((playerId) => {
            publishPlayerAction({
                type: 'dealTilesEvent',
                playerId,
                tileIds: getTilesAssignedToPlayer(playerId),
            } as DealTilesEvent);
        });
        publishPlayerAction({
            type: 'displayPlayScene',
        } as DisplayPlaySceneEvent);
        close();
    }, [facilitatorOptions, players]);

    return (
        <>
            <Modal 
                opened={opened} onClose={close} size={'75%'} centered
                classNames={{
                    header: styles.mantineModalHeader,
                    content: styles.mantineModalContent,
                }}
                overlayProps={{
                    backgroundOpacity: 0.55,
                    blur: 3,
                }}>
                <Box p={'sm'}>

                    {/* Modal panel */}
                    {step === 0 && <FacilitatorOptionsPanel/>}
                    {step === 1 && <FacilitatorTileMoving/>}
                    {step === 2 && <FacilitatorDealCardsConsent 
                        onConfirm={startGame} 
                        onDecline={() => setStep(existing => existing - 1)}/>}

                    {/* Navigation buttons */}
                    <Flex p={'md'} justify={'flex-end'}>
                        {step <= 1 && <Button role='button' m={'.4%'} name='back' onClick={() => setStep(prev => prev - 1)} disabled={step <= 0}>Back</Button>}
                        {step === 0 && <Button role='button' m={'.4%'} data-testid='modal-next-btn' name='next' onClick={dealTiles} disabled={selectedPlayersLength() <= 1}>Next</Button>}
                        {step === 1 && <Button role='button' m={'.4%'} data-testid='modal-next-btn' name='next' onClick={() => setStep(existing => existing + 1)} disabled={selectedPlayersLength() <= 1}>Next</Button>}
                    </Flex>

                </Box>
            </Modal>
            <Button
                rightSection={<IconPlayerPlay size={14}/>}
                radius={'xl'}
                variant="gradient"
                onClick={openStartModal}
                gradient={{from: 'blue', to: 'teal', deg: 90}}>
                Deal tiles
            </Button>
        </>
    );
}
