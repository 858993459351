import {useCoordinationRole, useGameServerConnection, usePlayers} from '@glark-newco/game-library';
import _ from 'lodash';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {CrunchTimeEvent, SignlinesPayload} from '../SignlinesPayloadTypes';
import {useCrunchTimeStartTime} from '../state/signlinesGameState';

export function useCrunchTime() {
    const {localPlayer} = usePlayers();
    const {publishPlayerAction} = useGameServerConnection<SignlinesPayload>();
    const crunchTimeStartTime = useCrunchTimeStartTime();
    const {isCoordinator} = useCoordinationRole();

    const crunchTimeDuration = 20000;

    const isCrunchTime = useMemo(() => {
        return !!crunchTimeStartTime;
    }, [crunchTimeStartTime]);

    const publishCrunchTimeEvent = useCallback(() => {
        if (!localPlayer.isFacilitator && !isCoordinator)
            return; // TODO - Add test for publishing by a non-facilitator coordinator
        publishPlayerAction({
            type: 'crunchTimeEvent',
        } as CrunchTimeEvent);
    }, [localPlayer.isFacilitator, publishPlayerAction]);

    return {isCrunchTime, publishCrunchTimeEvent, crunchTimeStartTime, crunchTimeDuration};
}

export function useCrunchTimeTransition(delay: number = 0): boolean {
    const crunchTimeStartTime = useCrunchTimeStartTime();
    const initialCrunchTimeState = useRef(crunchTimeStartTime);
    const [playAnimation, setPlayAnimation] = useState<boolean>(false);

    useEffect(() => {
        setTimeout(()=> {
            setPlayAnimation(!_.isEqual(crunchTimeStartTime, initialCrunchTimeState.current));
        }, delay);
    }, [crunchTimeStartTime]);

    return playAnimation;
}
