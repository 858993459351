import {PlayerId, usePlayers} from '@glark-newco/game-library';
import {useCallback} from 'react';
import {atom, useRecoilState} from 'recoil';
import {TileId} from '../hooks/useSignlinesBoard';
import {dealRandom, dealTeamvineStrategy, PlayerHand} from '../utils/SignlinesDealUtils';

export type DealStrategies = 'Random' | 'Teamvine Strategy';

interface DealOption {
    name: DealStrategies,
    description: string,
    dealer: (hands: number) => PlayerHand[]
}

export const dealOptions: DealOption[] = [{
    name: 'Random',
    description: 'Tiles are assigned randomly between players.',
    dealer: (hands: number) => dealRandom(hands),
}, {
    name: 'Teamvine Strategy',
    description: 'Corner tiles are assigned to the smallest number of players. Edge tiles are next assigned to the smallest number of players. Center tiles are distributed amongst remaining players.',
    dealer: (hands: number) => dealTeamvineStrategy(hands),
}];

export interface SelectedPlayersTiles {
    [playerId: PlayerId]: TileId[] | []
}

export interface FacilitatorOptions {
    dealStrategy: DealStrategies,
    selectedPlayersTiles: SelectedPlayersTiles | {},
}

const defaultFacilitatorOptionsState: FacilitatorOptions = {
    dealStrategy: 'Random',
    selectedPlayersTiles: {},
};

export const FacilitatorOptionsStateAtom = atom<FacilitatorOptions>({
    key: 'facilitatorOptionsAtom',
    default: defaultFacilitatorOptionsState,
});

/////////////////////////   HOOKS   ////////////////////////

export const useFacilitatorOptions = (): {
    facilitatorOptions: FacilitatorOptions,
    setDealStrategy: (dealStrategy: DealStrategies) => void,
    addSelectedPlayer: (addedPlayerId: PlayerId | PlayerId[]) => void,
    removeSelectedPlayer: (removedPlayerId: PlayerId) => void,
    isPlayerSelected: (playerId: PlayerId) => boolean,
    selectedPlayersLength: () => number,
    getTilesAssignedToPlayer: (playerId: PlayerId) => TileId[] | [],
    giveSelectedPlayersTiles: (playersTiles: SelectedPlayersTiles) => void
} => {
    const [facilitatorOptions, updateFacilitatorOptions] = useRecoilState(FacilitatorOptionsStateAtom);
    const {players} = usePlayers();

    const setDealStrategy = useCallback((dealStrategy: DealStrategies) => {
        updateFacilitatorOptions((existing) => ({...existing, dealStrategy}));
    }, [updateFacilitatorOptions]);
    

    const addSelectedPlayer = useCallback((addedPlayerId: PlayerId | PlayerId[]) => {
        const addedPlayers = Array.isArray(addedPlayerId) ? addedPlayerId : [addedPlayerId];
        const tempPlayersTiles: SelectedPlayersTiles = {...facilitatorOptions.selectedPlayersTiles};

        addedPlayers.forEach(addedPlayer => {
            const player = players.find(p => p.playerId === addedPlayer);
            // if (!player) { return; }
            if (player?.isFacilitator) { return; }
            if (addedPlayer in facilitatorOptions.selectedPlayersTiles) { return; }
            tempPlayersTiles[addedPlayer] = [];
        });
        
        updateFacilitatorOptions((existing) => ({...existing, selectedPlayersTiles: tempPlayersTiles}));
    }, [players, facilitatorOptions, updateFacilitatorOptions]);


    const removeSelectedPlayer = useCallback((removedPlayer: PlayerId) => {
        if (!Object.keys(facilitatorOptions.selectedPlayersTiles).includes(removedPlayer)) {
            console.warn('Error: Player does not tiles found among players.');
            return;
        }
        const newSelectedPlayersTiles: SelectedPlayersTiles = {...facilitatorOptions.selectedPlayersTiles};
        delete newSelectedPlayersTiles[removedPlayer];
        updateFacilitatorOptions((existing) => ({...existing, selectedPlayersTiles: newSelectedPlayersTiles}));
    }, [facilitatorOptions, updateFacilitatorOptions]);


    const isPlayerSelected = useCallback((playerId: PlayerId) => {
        return Object.keys(facilitatorOptions.selectedPlayersTiles).includes(playerId);
    }, [facilitatorOptions]);


    const selectedPlayersLength = useCallback(() => {
        return Object.keys(facilitatorOptions.selectedPlayersTiles).length;
    }, [facilitatorOptions]);


    const getTilesAssignedToPlayer = useCallback((playerId: PlayerId) => {
        if (!Object.keys(facilitatorOptions.selectedPlayersTiles).includes(playerId)) {
            return [];
        }
        return [...(facilitatorOptions.selectedPlayersTiles as SelectedPlayersTiles)[playerId]];
    }, [facilitatorOptions.selectedPlayersTiles]);


    const giveSelectedPlayersTiles = useCallback((playersTiles: SelectedPlayersTiles) => {
        const allTiles = Object.values(playersTiles).flat();
        const uniqueTiles = new Set(allTiles);
        if (uniqueTiles.size !== allTiles.length) {
            console.error('Error: Duplicate tiles found among players.');
            return;
        }
        updateFacilitatorOptions((existing) => ({...existing, selectedPlayersTiles: playersTiles}));
    }, [facilitatorOptions, facilitatorOptions.selectedPlayersTiles, updateFacilitatorOptions]);

    return {facilitatorOptions, setDealStrategy, addSelectedPlayer, removeSelectedPlayer, isPlayerSelected, selectedPlayersLength, getTilesAssignedToPlayer, giveSelectedPlayersTiles};
};
