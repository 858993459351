import {ServerTimeProvider} from '@glark-newco/game-library';
import {Sprite} from '@pixi/react';
import {Assets} from 'pixi.js';
import {Scene} from './Scene';
import {CLOSE_ICON, PLAY_BACKGROUND} from '../assetManifest';
import {CrunchTimeListener} from '../components/CrunchTimeListener';
import {TensionSoundEffect} from '../components/TensionSoundEffect';
import {FlashingCountdownAnimation} from '../pixijs/FlashingCountDownAnimation';
import {GameBoard} from '../pixijs/GameBoard';
import {PlayersTiles} from '../pixijs/PlayersTiles';
import {TileSprite} from '../pixijs/TileSprite';
import {TimePanel} from '../pixijs/TimePanel';
import {useTileInteraction} from '../state/TileDraggingState';
import {TILE_HEIGHT, TILE_WIDTH} from '../utils/GameBoardUtils';


function DraggedTile() {
    const {isDragging, tile, position, offset} = useTileInteraction();

    if (isDragging && tile) {
        return (
            <TileSprite
                tileId={tile.tileId}
                x={position.x - (offset?.x ?? 0)}
                y={position.y - (offset?.y ?? 0)}
                anchor={0}
                data-testid={'DraggedTile'}
                interactive={true}
            />
        );
    } else {
        return <></>;
    }
}

function ZoomedTile() {
    const {isZoom, tile, setZoom} = useTileInteraction();

    if (isZoom && tile) {
        return (
            <TileSprite
                tileId={tile.tileId}
                x={500 + (TILE_WIDTH * 2.5)} 
                y={165 + (TILE_HEIGHT * 2.5)} 
                width={TILE_WIDTH * 5} height={TILE_HEIGHT * 5}
                data-testid={'ZoomedTile'}
            >
                <Sprite
                    texture={Assets.get(CLOSE_ICON)}
                    width={15} height={15}
                    x={(TILE_WIDTH / 2) - 14} y={-(TILE_HEIGHT / 2) + 13}
                    anchor={.5}
                    interactive={true}
                    onclick={() => void setZoom(undefined)}
                />
            </TileSprite>
        );
    } else {
        return <></>;
    }
}

export function Play() {
    const {handleMouseMove} = useTileInteraction();

    return (
        // MoveHandler isn't registered without the inline function wrapping the void result of a promise.
        <Scene mouseMoveHandler={(event) => void handleMouseMove(event)}>
            <Sprite texture={Assets.get(PLAY_BACKGROUND)}/>
            <ServerTimeProvider>
                <TimePanel/>
                <TensionSoundEffect/>
                <CrunchTimeListener/>
                <GameBoard/>
                <PlayersTiles/>
                <FlashingCountdownAnimation/>
            </ServerTimeProvider>
            <DraggedTile/>
            <ZoomedTile/>
        </Scene>
    );
}