
import {Player, PlayerBadge, PlayerId, usePlayers} from '@glark-newco/game-library';
import {Box, Checkbox, Grid, Radio, Stack, Text, UnstyledButton} from '@mantine/core';
import {useCallback} from 'react';
import styles from './FacilitatorOptionsPanel.module.css';
import {SignlinesAvatarProvider} from './SignlinesAvatar';
import {dealOptions, useFacilitatorOptions} from '../state/FacilitatorOptionsAtom';


function PlayerSelectionButton({player}: {player: Player}) {
    const {facilitatorOptions, addSelectedPlayer, removeSelectedPlayer, isPlayerSelected} = useFacilitatorOptions();

    const handlePlayerSelect = useCallback((playerId: PlayerId) => {
        if (isPlayerSelected(playerId)) {
            removeSelectedPlayer(playerId);
        } else {
            addSelectedPlayer(playerId);
        }
    }, [facilitatorOptions]);

    return (
        <UnstyledButton onClick={() => handlePlayerSelect(player.playerId)} data-testid={`select_${player.playerId}`} className={styles.playerSelectionButton}>
            <Box style={{position: 'absolute', top: '50%', left: '1.6rem', transform: 'translate(-50%,-50%)'}}>
                <Checkbox
                    checked={isPlayerSelected(player.playerId)}
                    onChange={() => {}}
                    tabIndex={-1}
                    size="sm"
                    readOnly={true}
                    styles={{input: {cursor: 'pointer'}}}
                    aria-hidden
                />
            </Box>
            <PlayerBadge player={player} avatar={SignlinesAvatarProvider}/>
        </UnstyledButton>
    );
}

export function FacilitatorOptionsPanel() {
    const {facilitatorOptions, setDealStrategy} = useFacilitatorOptions();
    const {players} = usePlayers();

    return (<>
        <Box p={'sm'} data-testid='modal-select-player-options-page'>
            <Box><Text style={{textTransform: 'uppercase', fontWeight: 'bold', marginBottom: '1%'}}>Select Players</Text></Box>
            <Grid>
                {players.filter(player => !player.isFacilitator).map(player => (
                    <Grid.Col span={3} key={`facilitator_select_${player.playerId}`}>
                        <PlayerSelectionButton player={player}/>
                    </Grid.Col>
                ))}
            </Grid>
        </Box>
        <Box p={'sm'}>
            <Box><Text style={{textTransform: 'uppercase', fontWeight: 'bold', marginBottom: '1%'}}>Deal Options</Text></Box>
            <Stack>
                {dealOptions.map(option => (
                    <UnstyledButton data-testid={`radio_${option.name}`} key={`deal_option_${option.name}`}
                        onClick={() => setDealStrategy(option.name)}
                        className={styles.dealStrategyButton}>
                        <Radio size="md" mr="xl" readOnly={true} value={option.name}
                            checked={facilitatorOptions.dealStrategy === option.name}/>
                        <div>
                            <Text className={styles.dealOptionName}>{option.name}</Text>
                            <Text>{option.description}</Text>
                        </div>
                    </UnstyledButton>
                ),
                )}
            </Stack>
        </Box>
    </>);
}