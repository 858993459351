import {useGameServerConnection} from '@glark-newco/game-library';
import {Button} from '@mantine/core';
import {IconPlayerPlay} from '@tabler/icons-react';
import {EndGameEvent, SignlinesPayload} from '../SignlinesPayloadTypes';

export function EndGameButton() {
    const {publishPlayerAction} = useGameServerConnection<SignlinesPayload>();

    function publishEndGameEvent() {
        publishPlayerAction({
            type: 'endGameEvent',
        } as EndGameEvent);
    }

    return (
        <Button
            rightSection={<IconPlayerPlay size={14}/>}
            radius={'xl'}
            variant="gradient"
            onClick={() => publishEndGameEvent()}
            gradient={{from: 'blue', to: 'teal', deg: 90}}
        >
            Review stats
        </Button>
    );
}