import {useGameServerConnection, usePlayers} from '@glark-newco/game-library';
import {Button} from '@mantine/core';
import {IconPlayerPlay} from '@tabler/icons-react';
import {useCallback} from 'react';
import {SignlinesPayload, StartTimerEvent} from '../SignlinesPayloadTypes';

export function StartTimerButton() {
    const {localPlayer} = usePlayers();
    const {publishPlayerAction} = useGameServerConnection<SignlinesPayload>();

    const publishStartEvent = useCallback(() =>{
        if (!localPlayer.isFacilitator) return;
        publishPlayerAction({
            type: 'startTimerEvent',
            duration: 30, // minutes
        } as StartTimerEvent);
    }, [publishPlayerAction]);

    return (
        <Button
            data-testid="crunchtime-override"
            role="button"
            name="start game"
            rightSection={<IconPlayerPlay size={14}/>}
            radius={'xl'}
            variant="gradient"
            onClick={() => publishStartEvent()}
            gradient={{from: 'blue', to: 'teal', deg: 90}}>
            Start Timer
        </Button>
    );
}
