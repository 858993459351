import {useServerTime} from '@glark-newco/game-library';
import {PixiRef, Text} from '@pixi/react';
import {TextStyle} from 'pixi.js';
import {useEffect, useRef, useState} from 'react';
import {useAnimation} from '../hooks/useAnimation';
import {useCrunchTime} from '../hooks/useCrunchTime';
import {useGameOver} from '../hooks/useGameOver';

export type IText = PixiRef<typeof Text>;

export function FlashingCountdownAnimation() {
    const textRef = useRef<IText>(null);
    const [currentCount, setCurrentCount] = useState(0);
    const animationTimeMs = 1000;
    const {isCrunchTime, crunchTimeStartTime, crunchTimeDuration} = useCrunchTime();
    const {isGameOver} = useGameOver();
    const {currentTime} = useServerTime();

    useEffect(() => {
        if (!isCrunchTime || !crunchTimeStartTime) return;
        const elapsed = currentTime.diff(crunchTimeStartTime);
        const remaining = crunchTimeDuration - elapsed;
        const secondsRemaining = Math.max(Math.ceil(remaining / 1000), 0) - 1; // dont drop below zero
        setCurrentCount(secondsRemaining);
    }, [currentTime, crunchTimeStartTime, isCrunchTime, crunchTimeDuration]);

    useAnimation({
        animation: (progress: number) => {
            const scale = 0.5 + progress * 1.0;
            const alpha = 1 - progress;
            if (textRef.current) {
                textRef.current.visible = true;
                textRef.current.scale = {x: scale, y: scale};
                textRef.current.alpha = alpha;
            }
        },
        animationTimeMs,
        cleanup: () => {
            if (textRef.current) {
                textRef.current.visible = false;
            }
        },
    }, [currentCount]); // Re-run animation whenever currentCount changes

    // @ts-expect-error Cypress not recognised on window object
    const isCypress = !!window.Cypress; // Skips rendering component in cypress (we can't test animations yet, and it ruins gameboard snapshots)
    if (isGameOver || isCypress) return; // Stops possibility of countdown being shown over congrats

    return (
        <Text 
            ref={textRef}
            eventMode='none' // Allows you to click elements behind countdown
            text={String(currentCount)}
            x={960}
            y={390}
            anchor={0.5}
            zIndex={2}
            style={{
                fill: '#c8a528',
                fontVariant: 'small-caps',
                fontSize: 180,
                fontWeight: 'bold',
                stroke: '#38240d',
                strokeThickness: 5,
            } as TextStyle}
        />
    );
}
