import {GameEvent, isPlayerAction, Scene, SceneMutator} from '@glark-newco/game-library';
import {isEndGameEvent, isPlayScenePayload} from '../SignlinesPayloadTypes';


export const SignLinesSceneMutator: SceneMutator = (existing: Scene, event: GameEvent)=> {
    if (isPlayerAction(event) && isPlayScenePayload(event.payload)) {
        return 'PLAY';
    } else if (isPlayerAction(event) && isEndGameEvent(event.payload)) {
        return 'REVIEW';
    }
    return existing;
};
